import { GetStripeCheckoutLink } from "../apis/subscription";
import { CreateAFeed, GetFeeds } from "../apis/feeds";

export const HandleSubscribe = async (authToken, setStripeLoading) => {
  setStripeLoading(true);
  const response = await GetStripeCheckoutLink(authToken);
  setStripeLoading(false);
  window.gtag_start_subscription();
  window.fbq("track", "Lead", {});
  window.location.href = response.checkoutUrl;
};

export const Delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const CreateFeedAndSet = async (
  authToken,
  seed,
  useAnon,
  setFeeds,
  setSelectedFeed
) => {
  await CreateAFeed(authToken, seed, useAnon);
  const allFeeds = await GetFeeds(authToken, useAnon);
  setFeeds(allFeeds);
  setSelectedFeed(allFeeds[0].uuid);
};
