/** @jsxImportSource @emotion/react */
import { useState } from "react";
import PropTypes from "prop-types";
import * as Dialog from "@radix-ui/react-dialog";
import * as modalStyles from "../modalStyles";
import * as styles from "./styles";
import { SettingsIcon, DeleteIcon } from "./icons";
import { DeleteFeed, GetFeeds } from "../../../apis/feeds";
import { FeedPropType } from "../../propTypes";

const FeedSettings = ({
  authToken,
  feeds,
  setFeeds,
  setSelectedFeed,
  useAnon,
}) => {
  const [deleteFeed, setDeleteFeed] = useState(null);

  const handleDeleteFeed = async (feed) => {
    await DeleteFeed(authToken, feed.uuid, useAnon);
    const allFeeds = await GetFeeds(authToken, useAnon);
    setFeeds(allFeeds);
    setSelectedFeed(allFeeds[0].uuid);
    setDeleteFeed(null);
  };

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <button css={styles.TriggerButton}>
          <SettingsIcon />
        </button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay css={modalStyles.Overlay} />
        <Dialog.Content
          css={modalStyles.Content}
          aria-describedby="manage your feeds"
        >
          <div css={styles.Body}>
            <Dialog.Title css={styles.Title}>Manage Your Feeds</Dialog.Title>
            <Dialog.Description css={styles.Description}>
              View all your feeds below
            </Dialog.Description>
            <div css={styles.FeedList}>
              {feeds?.map((feed) => {
                if (deleteFeed === feed.uuid) {
                  return (
                    <div
                      css={[styles.FeedItem, styles.FeedItemDelete]}
                      key={feed.uuid}
                    >
                      <span css={[styles.FeedName, styles.DeleteConfirmation]}>
                        Delete this feed?
                      </span>
                      <div css={styles.ConfirmDeleteButtons}>
                        <button
                          css={[styles.ConfirmButton, styles.CancelDelete]}
                          onClick={() => {
                            setDeleteFeed(null);
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          css={[styles.ConfirmButton, styles.ConfirmDelete]}
                          onClick={() => {
                            handleDeleteFeed(feed);
                          }}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div css={styles.FeedItem} key={feed.uuid}>
                      <span css={styles.FeedName}>{feed.name}</span>
                      {feeds?.length > 1 && (
                        <button
                          css={styles.DeleteFeed}
                          onClick={() => {
                            setDeleteFeed(feed.uuid);
                          }}
                        >
                          <DeleteIcon />
                        </button>
                      )}
                    </div>
                  );
                }
              })}
            </div>

            <div css={styles.ButtonContainer}>
              <Dialog.Close asChild>
                <button css={[styles.Button, styles.Cancel]}>Close</button>
              </Dialog.Close>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

FeedSettings.propTypes = {
  authToken: PropTypes.string,
  feeds: PropTypes.arrayOf(FeedPropType),
  setFeeds: PropTypes.func.isRequired,
  setSelectedFeed: PropTypes.func.isRequired,
  useAnon: PropTypes.bool,
};

export default FeedSettings;
