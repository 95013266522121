import { css } from "@emotion/react";
import theme from "../../theme";

export const Container = css`
  display: flex;
  justify-content: space-between;
  width: 80%;
  align-items: center;
  position: fixed;
  background: ${theme.colors.grey100};
  padding: 20px 0 10px 0;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const Company = css`
  font-size: 24px;
  font-weight: bold;
  font-fam ily: Libre Baskerville, sans-serif;
`;

export const LogoContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 40px;
`;

export const Row = css`
  display: flex;
  gap: 10px;
  align-items: center;
  position: relative;
`;

export const Credits = css`
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  color: ${theme.colors.grey500};
`;

export const LimitReached = css`
  color: ${theme.colors.red800};
`;

export const ReturnButton = css`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  cursor: pointer;
  color: ${theme.colors.primary800};
`;

export const LoginButton = css`
  display: flex;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  color: black;
  background: ${theme.colors.yellow700};
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  &:hover {
    background: ${theme.colors.yellow800};
  }
  &:active {
    background: ${theme.colors.yellow700};
  }
`;

export const Avatar = css`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.yellow800};
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: black;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;

export const AccountDropdown = css`
  display: none;
  flex-direction: column;
  position: absolute;
  top: 50px;
  right: 0;
  background: ${theme.colors.grey100};
  padding: 20px;
  border-radius: 10px;
  width: 200px;
  align-items: center;
  gap: 20px;
  border: 2px solid ${theme.colors.grey300};
  z-index: 10;
`;

export const ShowAccountDropdown = css`
  display: flex;
`;

export const DropdownGroup = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const Username = css`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: ${theme.colors.grey500};
  padding: 0 10px;
`;

export const BuyCredits = css`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: ${theme.colors.primary800};
  border-radius: 10px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: white;
  &:hover {
    background: ${theme.colors.primary700};
  }
  &:active {
    background: ${theme.colors.primary800};
  }
`;

export const AccountOption = css`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  gap: 10px;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 10px;
  &:hover {
    background: ${theme.colors.grey200};
  }
  &:active {
    background: ${theme.colors.grey300};
  }
`;
