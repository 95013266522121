export const DetermineHeaders = (authToken, useAnon) => {
  let headers;
  if (useAnon) {
    headers = {
      "Content-Type": "application/json",
      "Anonymous-Authorization": authToken,
    };
  } else {
    headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    };
  }
  return headers;
};
