import { css } from "@emotion/react";
import theme from "../../theme";

export const Container = css`
  display: none;
  flex-direction: column;
  width: 80%;
  gap: 20px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const Show = css`
  display: flex;
`;

export const Title = css`
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  color: ${theme.colors.grey700};
`;

export const Body = css`
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: ${theme.colors.grey600};
`;

export const Card = css`
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: white;
  border-radius: 10px;
  gap: 10px;
`;

export const Button = css`
  display: flex;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  background: ${theme.colors.primary800};
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  color: white;
  &:hover {
    background: ${theme.colors.primary900};
  }
  &:active {
    background: ${theme.colors.primary800};
  }
`;

export const Join = css`
  background: ${theme.colors.yellow700};
  color: black;
  &:hover {
    background: ${theme.colors.yellow800};
  }
  &:active {
    background: ${theme.colors.yellow700};
  }
`;

export const TitleContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;
