/** @jsxImportSource @emotion/react */
import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import * as styles from "./styles";
import { Logo } from "./icons";
import { MePropType } from "../propTypes";
import AccountDropdown from "./AccountDropdown";

const Navbar = ({ authToken, feeds, me, useAnon, origin }) => {
  const [showAccountDropdown, setShowAccountDropdown] = useState(false);

  const { loginWithRedirect, user, isAuthenticated } = useAuth0();
  const dropdownRef = useRef(null);
  const avatarRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      avatarRef.current &&
      !avatarRef.current.contains(event.target)
    ) {
      setShowAccountDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div css={styles.Container}>
      <div css={styles.Row}>
        <span css={styles.Company}>
          <div css={styles.LogoContainer}>
            <Logo />
          </div>
        </span>
      </div>
      <div css={styles.Row}>
        {origin !== "legal" ? (
          <span
            css={[
              styles.Credits,
              feeds?.length === me?.number_of_feeds && styles.LimitReached,
            ]}
          >
            {feeds?.length} / {me?.number_of_feeds} feeds
          </span>
        ) : (
          <button
            type="button"
            onClick={() => {
              window.location.href = "/";
            }}
            css={styles.ReturnButton}
          >
            Return to App
          </button>
        )}
        {!isAuthenticated ? (
          <button
            css={styles.LoginButton}
            onClick={() => {
              loginWithRedirect();
              window.gtag_start_login_signup();
              window.fbq("track", "Lead", {});
            }}
          >
            Join Now
          </button>
        ) : (
          <button
            css={styles.Avatar}
            onClick={() => {
              setShowAccountDropdown(!showAccountDropdown);
            }}
            ref={avatarRef}
          >
            {user?.name.substring(0, 1)}
          </button>
        )}
        <AccountDropdown
          authToken={authToken}
          me={me}
          useAnon={useAnon}
          showAccountDropdown={showAccountDropdown}
          dropdownRef={dropdownRef}
        />
      </div>
    </div>
  );
};

Navbar.propTypes = {
  authToken: PropTypes.string,
  feeds: PropTypes.array,
  me: MePropType,
  useAnon: PropTypes.bool,
  origin: PropTypes.string,
};

export default Navbar;
