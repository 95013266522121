/** @jsxImportSource @emotion/react */
import { useState } from "react";
import propTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import { HandleSubscribe } from "../../helpers";
import { CloseIcon } from "../icons";
import * as styles from "../styles";
import Loader from "../../Loader";

const SubscribeMessage = ({
  localStorageKey,
  authToken,
  showMessage,
  setShowMessage,
}) => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

  const [stripeLoading, setStripeLoading] = useState(false);

  return (
    <div css={[styles.Container, showMessage !== "hide" && styles.Show]}>
      <div css={styles.Card}>
        <div css={styles.TitleContainer}>
          <span css={styles.Title}>Subscribe for more feeds</span>
          <button
            css={styles.ButtonContainer}
            onClick={() => {
              localStorage.setItem(localStorageKey, "hide");
              setShowMessage("hide");
            }}
          >
            <CloseIcon />
          </button>
        </div>
        <span css={styles.Body}>
          Subscribe for only $5/month to unlock the ability to create 20 feeds.
        </span>
        {!isAuthenticated && !isLoading && (
          <button
            type="button"
            css={[styles.Button, styles.Join]}
            onClick={() => {
              loginWithRedirect();
              window.fbq("track", "Lead", {});
              window.gtag_start_login_signup();
            }}
          >
            Join Now
          </button>
        )}
        {isAuthenticated && !isLoading && (
          <button
            css={styles.Button}
            onClick={() => {
              HandleSubscribe(authToken, setStripeLoading);
            }}
          >
            {stripeLoading ? (
              <Loader background="#ffffff" height={22} width={22} />
            ) : (
              "Subscribe"
            )}
          </button>
        )}
      </div>
    </div>
  );
};

SubscribeMessage.propTypes = {
  localStorageKey: propTypes.string,
  authToken: propTypes.string,
  showMessage: propTypes.func,
  setShowMessage: propTypes.func,
};

export default SubscribeMessage;
