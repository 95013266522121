import { css } from "@emotion/react";
import theme from "../../theme";

export const Container = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  background: ${theme.colors.grey200};
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: fixed;
  bottom: 0;
  gap: 10px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const CurrentlyPlaying = css`
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 22px;
  color: ${theme.colors.grey500};
  font-weight: 600;
  text-align: center;
  font-family: ${theme.header.font};
`;

export const PrimaryControls = css`
  display: flex;
  gap: 30px;
  justify-content: center;
  align-items: center;
`;

export const SkipButton = css`
  display: flex;
  cursor: pointer;
`;

export const PlayPauseButton = css`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.colors.primary800};
  height: 40px;
  width: 40px;
  border-radius: 50%;
`;

export const SeekBar = css`
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;

export const Duration = css`
  font-size: 12px;
  line-height: 20px;
  color: ${theme.colors.grey500};
`;

export const SliderRoot = css`
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 100%;
  height: 20px;
  cursor: pointer;
`;

export const SliderTrack = css`
  background-color: ${theme.colors.grey300};
  position: relative;
  flex-grow: 1;
  border-radius: 9999px;
  height: 6px;
`;

export const SliderRange = css`
  position: absolute;
  background-color: ${theme.colors.grey600};
  border-radius: 9999px;
  height: 100%;
`;

export const SliderThumb = css`
  display: block;
  width: 0px;
  height: 0px;
  background-color: ${theme.colors.grey600};
  box-shadow: black);
  border-radius: 10px;
  cursor: pointer;
`;
