/** @jsxImportSource @emotion/react */
import { useRef, useState, useEffect, useCallback } from "react";
import _ from "lodash";
import ReactPlayer from "react-player";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import * as Slider from "@radix-ui/react-slider";
import * as styles from "./styles";
import { PlayIcon, PauseIcon, SkipForward, SkipBackward } from "./icons";
import theme from "../../theme";
import { MarkEpisodeListened } from "../../apis/episodes";
import { GetFeedsEpisodes } from "../../apis/feeds";

const PlayerControls = ({
  authToken,
  playlist,
  episode,
  setEpisode,
  playing,
  setPlaying,
  setPlaylist,
  selectedFeed,
  useAnon,
}) => {
  const playerRef = useRef(null);
  const [played, setPlayed] = useState(0);
  const [duration, setDuration] = useState(0);

  const setPlaylistCallback = useCallback(
    (episodes) => setPlaylist(episodes),
    [setPlaylist]
  );

  useEffect(() => {
    const handleListened = async () => {
      const currentTrackIndex = _.findIndex(playlist, {
        episode: { uuid: episode?.uuid },
      });
      if (
        playing &&
        playlist[currentTrackIndex]?.episode &&
        playlist[currentTrackIndex]?.episode?.seen === false
      ) {
        await MarkEpisodeListened(
          authToken,
          playlist[currentTrackIndex]?.episode?.uuid,
          useAnon
        );
        const episodes = await GetFeedsEpisodes(
          authToken,
          selectedFeed,
          useAnon
        );
        setPlaylistCallback(episodes);
      }
    };
    handleListened();
  }, [
    playing,
    episode,
    authToken,
    playlist,
    selectedFeed,
    setPlaylistCallback,
    useAnon,
  ]);

  const togglePlay = () => {
    setPlaying(!playing);
  };

  const handleProgress = (state) => {
    setPlayed(state.played);
  };

  const handleSeek = (skipTo) => {
    const newPlayed = parseFloat(skipTo);
    setPlayed(newPlayed);
    playerRef.current.seekTo(newPlayed);
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const restartTrack = () => {
    setPlayed(0);
    setPlaying(true);
    // Ensures the player starts from the beginning of the next track
    setTimeout(() => {
      playerRef.current.seekTo(0, "seconds");
    }, 100);
  };

  const handleNext = () => {
    const currentTrackIndex = _.findIndex(playlist, {
      episode: { uuid: episode?.uuid },
    });
    if (currentTrackIndex >= 0) {
      const nextTrack = playlist[currentTrackIndex + 1]?.episode;
      if (nextTrack) {
        setEpisode(nextTrack);
        restartTrack();
      }
    }
  };

  const handlePrevious = () => {
    if (played > 0.05) {
      restartTrack();
      return;
    }
    const currentTrackIndex = _.findIndex(playlist, {
      episode: { uuid: episode?.uuid },
    });
    if (currentTrackIndex >= 0) {
      const previousTrack = playlist[currentTrackIndex - 1]?.episode;
      if (previousTrack) {
        setEpisode(previousTrack);
        restartTrack();
      } else {
        setPlaying(false);
      }
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <div css={styles.Container}>
      <ReactPlayer
        ref={playerRef}
        playing={playing}
        onProgress={handleProgress}
        onDuration={handleDuration}
        onEnded={handleNext}
        controls={false} // Hide default controls
        url={episode?.audio}
        style={{ display: "none" }} // Hide the player
      />
      <span css={styles.CurrentlyPlaying}>{episode?.name}</span>
      <div css={styles.PrimaryControls}>
        <motion.button
          whileHover={{ scale: theme.animation.whileHover }}
          whileTap={{ scale: theme.animation.whileTap }}
          css={styles.SkipButton}
          onClick={handlePrevious}
        >
          <SkipBackward />
        </motion.button>
        <motion.button
          whileHover={{ scale: theme.animation.whileHover }}
          whileTap={{ scale: theme.animation.whileTap }}
          css={styles.PlayPauseButton}
          onClick={togglePlay}
        >
          {playing ? <PauseIcon /> : <PlayIcon />}
        </motion.button>
        <motion.button
          whileHover={{ scale: theme.animation.whileHover }}
          whileTap={{ scale: theme.animation.whileTap }}
          css={styles.SkipButton}
          onClick={handleNext}
        >
          <SkipForward />
        </motion.button>
      </div>
      <div css={styles.SeekBar}>
        <span css={styles.Duration}>{formatTime(played * duration)}</span>
        <Slider.Root
          css={styles.SliderRoot}
          defaultValue={[0]}
          min={0}
          max={1}
          step={0.01}
          value={[played]}
          onValueChange={handleSeek}
        >
          <Slider.Track css={styles.SliderTrack}>
            <Slider.Range css={styles.SliderRange} />
          </Slider.Track>
          <Slider.Thumb css={styles.SliderThumb} aria-label="Seek" />
        </Slider.Root>
        <span css={styles.Duration}>{formatTime(duration)}</span>
      </div>
    </div>
  );
};

PlayerControls.propTypes = {
  authToken: PropTypes.string,
  playlist: PropTypes.array,
  episode: PropTypes.object,
  setEpisode: PropTypes.func.isRequired,
  playing: PropTypes.bool.isRequired,
  setPlaying: PropTypes.func.isRequired,
  setPlaylist: PropTypes.func.isRequired,
  selectedFeed: PropTypes.string,
  useAnon: PropTypes.bool,
};

export default PlayerControls;
