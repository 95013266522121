import axios from "axios";
import { DetermineHeaders } from "./helpers";

const { REACT_APP_API_DOMAIN } = process.env;

export const GetFeeds = async (authToken, useAnon) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${REACT_APP_API_DOMAIN}/api/feeds`,
      headers: DetermineHeaders(authToken, useAnon),
    });
    return response.data;
  } catch (error) {
    console.error("Error ", error);
    return;
  }
};

export const CreateAFeed = async (authToken, seed, useAnon) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${REACT_APP_API_DOMAIN}/api/feeds`,
      headers: DetermineHeaders(authToken, useAnon),
      data: {
        seed,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error ", error);
    return;
  }
};

export const GetFeedsEpisodes = async (authToken, feedId, useAnon) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${REACT_APP_API_DOMAIN}/api/feeds/${feedId}/episodes`,
      headers: DetermineHeaders(authToken, useAnon),
    });
    return response.data;
  } catch (error) {
    console.error("Error ", error);
    return;
  }
};

export const DeleteFeed = async (authToken, feedId, useAnon) => {
  try {
    const response = await axios({
      method: "DELETE",
      url: `${REACT_APP_API_DOMAIN}/api/feeds/${feedId}`,
      headers: DetermineHeaders(authToken, useAnon),
    });
    return response.data;
  } catch (error) {
    console.error("Error ", error);
    return;
  }
};
