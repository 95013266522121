/** @jsxImportSource @emotion/react */
import propTypes from "prop-types";
import { CloseIcon } from "../icons";
import * as styles from "../styles";

const WelcomeMessage = ({ localStorageKey, showMessage, setShowMessage }) => {
  return (
    <div css={[styles.Container, showMessage !== "hide" && styles.Show]}>
      <div css={styles.Card}>
        <div css={styles.TitleContainer}>
          <span css={styles.Title}>Welcome!</span>
          <button
            css={styles.ButtonContainer}
            onClick={() => {
              localStorage.setItem(localStorageKey, "hide");
              setShowMessage("hide");
            }}
          >
            <CloseIcon />
          </button>
        </div>
        <span css={styles.Body}>
          Wire.fm is your personal audio news feed, delivering the latest
          headlines, stories, and updates from reputable sources around the
          world.
        </span>
      </div>
    </div>
  );
};

WelcomeMessage.propTypes = {
  localStorageKey: propTypes.string,
  showMessage: propTypes.string,
  setShowMessage: propTypes.func,
};

export default WelcomeMessage;
