/** @jsxImportSource @emotion/react */
import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import io from "socket.io-client";
import { useSearchParams } from "react-router-dom";
import _ from "lodash";
import * as styles from "./styles";
import Navbar from "../Navbar/Navbar";
import Feeds from "../Feeds/Feeds";
import Playlist from "../Playlist/Playlist";
import PlayerControls from "../PlayerControls/PlayerControls";
import { GetFeedsEpisodes } from "../../apis/feeds";
import theme from "../../theme";
import Loader from "../Loader";
import { GetMe } from "../../apis/me";
import { v4 as uuidv4 } from "uuid";
import { LinkAnonFeeds } from "../../apis/link";
import { GetFeeds } from "../../apis/feeds";
import { CreateFeedAndSet } from "../helpers";
import ShowMessage from "../Messages/ShowMessage";

const Home = () => {
  const [searchParams] = useSearchParams();
  const searchTopic = searchParams.get("topic");
  const { isLoading, getIdTokenClaims, isAuthenticated } = useAuth0();
  const [playlist, setPlaylist] = useState([]);
  const [episode, setEpisode] = useState(undefined);
  const [playing, setPlaying] = useState(false);
  const [authToken, setAuthToken] = useState(undefined);
  const [selectedFeed, setSelectedFeed] = useState(undefined);
  const [feeds, setFeeds] = useState([]);
  const [fetchingEpisodes, setFetchingEpisodes] = useState(true);
  const [me, setMe] = useState(undefined);
  const [socket, setSocket] = useState(undefined);
  const [useAnon, setUseAnon] = useState(false);

  function setVhProperty() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  window.addEventListener("resize", setVhProperty);
  setVhProperty(); // Set initially on load

  const createFeedFromSearchTopic = async (
    token,
    searchTopic,
    meInfo,
    anon
  ) => {
    const currentFeeds = await GetFeeds(token, anon);
    const doesFeedExist = _.some(currentFeeds, { name: searchTopic });
    const hasMaxFeeds = currentFeeds?.length >= meInfo?.number_of_feeds;
    if (!doesFeedExist && !hasMaxFeeds) {
      await CreateFeedAndSet(
        token,
        searchTopic,
        anon,
        setFeeds,
        setSelectedFeed
      );
    }
  };

  useEffect(() => {
    const fetchIdToken = async () => {
      try {
        if (isAuthenticated && !isLoading) {
          setUseAnon(false);
          const idTokenClaims = await getIdTokenClaims();
          const idToken = idTokenClaims.__raw;
          setAuthToken(idToken);
          let meInfo = await GetMe(idToken);
          const anonToken = localStorage.getItem("anonToken");
          if (meInfo?.has_linked_before === false && anonToken) {
            await LinkAnonFeeds(idToken, anonToken);
            meInfo = await GetMe(idToken);
          }
          setMe(meInfo);
          setSocket(io(`wss://api.wire.fm?user_id=${meInfo?.id}`));
          if (searchTopic) {
            await createFeedFromSearchTopic(
              idToken,
              searchTopic,
              meInfo,
              false
            );
          }
        }
        if (!isAuthenticated && !isLoading) {
          let anonToken = localStorage.getItem("anonToken");
          if (!anonToken) {
            anonToken = uuidv4();
            localStorage.setItem("anonToken", anonToken);
          }
          setUseAnon(true);
          setAuthToken(anonToken);
          const meInfo = await GetMe(anonToken, true);
          setMe(meInfo);
          setSocket(io(`wss://api.wire.fm?user_id=${meInfo?.id}`));
          if (searchTopic) {
            await createFeedFromSearchTopic(
              anonToken,
              searchTopic,
              meInfo,
              true
            );
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchIdToken();
  }, [isAuthenticated, getIdTokenClaims, isLoading, searchTopic]);

  useEffect(() => {
    // Listen for messages
    socket?.on("feed_update", async (msg) => {
      console.log(msg);
      if (msg?.feed_id === selectedFeed) {
        const episodes = await GetFeedsEpisodes(
          authToken,
          selectedFeed,
          useAnon
        );
        setPlaylist(episodes);
      }
    });
    return () => {
      socket?.off("feed_update");
    };
  }, [authToken, selectedFeed, playing, socket, useAnon]);

  useEffect(() => {
    const fetchEpisodes = async () => {
      if (selectedFeed) {
        setFetchingEpisodes(true);
        const episodes = await GetFeedsEpisodes(
          authToken,
          selectedFeed,
          useAnon
        );
        setPlaylist(episodes);
        setFetchingEpisodes(false);
      }
    };
    fetchEpisodes();
  }, [selectedFeed, authToken, useAnon]);

  if (isLoading) {
    return (
      <div css={styles.LoadingContainer}>
        <Loader background={theme.colors.grey500} height={22} width={22} />
        Loading...
      </div>
    );
  }

  if (!authToken) {
    return (
      <div css={styles.Container}>
        <Navbar />
      </div>
    );
  }

  return (
    <div css={styles.Container}>
      <Navbar authToken={authToken} feeds={feeds} me={me} useAnon={useAnon} />
      <div css={styles.Spacer} />
      <ShowMessage feeds={feeds} me={me} authToken={authToken} />
      <Feeds
        authToken={authToken}
        feeds={feeds}
        setFeeds={setFeeds}
        selectedFeed={selectedFeed}
        setSelectedFeed={setSelectedFeed}
        setFetchingEpisodes={setFetchingEpisodes}
        setPlaylist={setPlaylist}
        me={me}
        useAnon={useAnon}
      />
      <Playlist
        authToken={authToken}
        playlist={playlist}
        episode={episode}
        setEpisode={setEpisode}
        playing={playing}
        setPlaying={setPlaying}
        fetchingEpisodes={fetchingEpisodes}
        setPlaylist={setPlaylist}
        useAnon={useAnon}
        selectedFeed={selectedFeed}
      />
      <PlayerControls
        authToken={authToken}
        playlist={playlist}
        episode={episode}
        setEpisode={setEpisode}
        playing={playing}
        setPlaying={setPlaying}
        setPlaylist={setPlaylist}
        selectedFeed={selectedFeed}
        useAnon={useAnon}
      />
    </div>
  );
};

export default Home;
