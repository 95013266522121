/** @jsxImportSource @emotion/react */
import { useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";
import { Logo } from "./icons";
import * as styles from "./styles";
import theme from "../../theme";

const LandingPage = () => {
  const [searchParams] = useSearchParams();
  const searchTopic = searchParams.get("topic");

  return (
    <div css={styles.Container}>
      <div css={styles.Card}>
        <Logo />
        <span css={styles.Headline}>
          Listen to the latest news about {searchTopic}
        </span>
        <span css={styles.Body}>
          Your personal audio news feed, delivering the latest headlines,
          stories, and updates from reputable sources around the world.
        </span>
        <motion.button
          whileHover={{ scale: theme.animation.whileHover }}
          whileTap={{ scale: theme.animation.whileTap }}
          css={styles.Button}
          onClick={() => {
            window.location.href = `/?topic=${searchTopic}`;
          }}
        >
          Create Audio Feed For {searchTopic}
        </motion.button>
      </div>
    </div>
  );
};

export default LandingPage;
