import theme from "../../theme";

export const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <path
      d="M16.946 15.2437C17.004 15.3018 17.0501 15.3707 17.0815 15.4466C17.1129 15.5225 17.1291 15.6038 17.1291 15.6859C17.1291 15.768 17.1129 15.8493 17.0815 15.9252C17.0501 16.0011 17.004 16.07 16.946 16.1281C16.8879 16.1862 16.8189 16.2322 16.7431 16.2636C16.6672 16.2951 16.5859 16.3112 16.5038 16.3112C16.4216 16.3112 16.3403 16.2951 16.2645 16.2636C16.1886 16.2322 16.1196 16.1862 16.0616 16.1281L10.8788 10.9445L5.69595 16.1281C5.57868 16.2454 5.41962 16.3112 5.25377 16.3112C5.08791 16.3112 4.92885 16.2454 4.81158 16.1281C4.6943 16.0108 4.62842 15.8517 4.62842 15.6859C4.62842 15.52 4.6943 15.361 4.81158 15.2437L9.99517 10.0609L4.81158 4.87808C4.6943 4.76081 4.62842 4.60175 4.62842 4.43589C4.62842 4.27004 4.6943 4.11098 4.81158 3.99371C4.92885 3.87643 5.08791 3.81055 5.25377 3.81055C5.41962 3.81055 5.57868 3.87643 5.69595 3.99371L10.8788 9.1773L16.0616 3.99371C16.1789 3.87643 16.3379 3.81055 16.5038 3.81055C16.6696 3.81055 16.8287 3.87643 16.946 3.99371C17.0632 4.11098 17.1291 4.27004 17.1291 4.43589C17.1291 4.60175 17.0632 4.76081 16.946 4.87808L11.7624 10.0609L16.946 15.2437Z"
      fill={theme.colors.grey500}
    />
  </svg>
);
