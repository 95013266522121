import { Global, css } from "@emotion/react";
import theme from "./theme";

const GlobalStyles = () => (
  <Global
    styles={css`
      html {
        scroll-behavior: smooth;
      }

      body {
        width: 100%;
        height: 100%;
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: ${theme.default.size};
        font-family: ${theme.default.font};
        background-color: ${theme.colors.grey100};
        color: black;
      }

      a {
        text-decoration: none;
        cursor: pointer;
        color: black;
      }

      button {
        all: unset;
        cursor: pointer;
      }

      input[type="file"] {
        display: none;
      }

      select {
        -webkit-appearance: none;
        appearance: none;
        border: none;
        cursor: pointer;
      }
    `}
  />
);

export default GlobalStyles;
