import axios from "axios";

const { REACT_APP_API_DOMAIN } = process.env;

export const GetStripeCheckoutLink = async (authToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${REACT_APP_API_DOMAIN}/api/stripe/create-checkout-session`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error ", error);
    return;
  }
};

export const GetStripeSubscription = async (authToken) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${REACT_APP_API_DOMAIN}/api/stripe/manage-subscription`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error ", error);
    return;
  }
};
