/** @jsxImportSource @emotion/react */
import * as styles from "./styles";

const Privacy = () => {
  return (
    <div css={styles.Content}>
      <span css={styles.Title}>Privacy Policy</span>
      <span css={styles.Text}>
        LoopGenius, Inc. (hereinafter referred to as &quot;Wire.fm,&quot;
        &quot;we,&quot; &quot;us,&quot; or &quot;our&quot;) takes the privacy of
        its users very seriously. This Privacy Policy (the &quot;Policy&quot;)
        is intended to provide you with information about what data we collect
        from you, how we use that data, and your rights in relation to that
        data.
      </span>

      <div css={styles.Section}>
        <span css={styles.Header}>Data Collection</span>
        <span css={styles.Text}>
          We collect certain data from our users in order to provide our
          service. This data includes: Personal information, such as your name,
          email address, and phone number; Information about your use of our
          service, such as the pages you visit and the actions you take within
          the service; Technical information, such as your IP address, browser
          type, and device information;
        </span>
      </div>

      <div css={styles.Section}>
        <span css={styles.Header}>Use of Data</span>
        <span css={styles.Text}>
          We use the data we collect from you to provide our service and to
          improve the service we offer. Specifically, we use your data to:
          Create and manage your account; Provide customer support; Send you
          marketing and promotional communications; Analyze usage data to
          improve our service and develop new features; Comply with legal and
          regulatory requirements.
        </span>
      </div>

      <div css={styles.Section}>
        <span css={styles.Header}>Sharing of Data</span>
        <span css={styles.Text}>
          We may share your data with third parties in certain circumstances,
          including: With our service providers and partners, who assist us in
          providing the service or in analyzing usage data; With law enforcement
          or other government agencies, as required by law or in response to a
          valid subpoena or court order; With other third parties, as necessary
          to protect our rights or the rights of others.
        </span>
      </div>

      <div css={styles.Section}>
        <span css={styles.Header}>Data Retention</span>
        <span css={styles.Text}>
          We retain your data for as long as necessary to provide our service
          and to comply with legal and regulatory requirements.
        </span>
      </div>

      <div css={styles.Section}>
        <span css={styles.Header}>Data Security</span>
        <span css={styles.Text}>
          We take reasonable measures to protect your data from unauthorized
          access, use, disclosure, alteration, or destruction. However, please
          be aware that no data transmission over the internet can be guaranteed
          to be 100% secure.
        </span>
      </div>

      <div css={styles.Section}>
        <span css={styles.Header}>Your Rights</span>
        <span css={styles.Text}>
          You have certain rights in relation to the data we collect from you.
          These rights include: The right to access your data; The right to
          request correction or deletion of your data; The right to object to
          the processing of your data; The right to withdraw your consent to the
          processing of your data.
        </span>
      </div>

      <div css={styles.Section}>
        <span css={styles.Header}>Changes to this Policy</span>
        <span css={styles.Text}>
          We may update this Policy from time to time. We will notify you of any
          changes by posting the new Policy on our website.
        </span>
      </div>

      <div css={styles.Section}>
        <span css={styles.Header}>Contact Us</span>
        <span css={styles.Text}>
          If you have any questions or concerns about this Policy or our data
          practices, please contact us at{" "}
          <a css={styles.Link} href="mailto:legal@wire.fm">
            legal@wire.fm
          </a>
        </span>
        <span css={styles.Text}>
          By using our service, you consent to the collection, use, and sharing
          of your data as set forth in this Policy.
        </span>
        <span css={styles.Text}>
          This Privacy Policy was last updated on <b>09/05/2024</b>.
        </span>
      </div>
    </div>
  );
};

export default Privacy;
