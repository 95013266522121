import axios from "axios";

const { REACT_APP_API_DOMAIN } = process.env;

export const LinkAnonFeeds = (authToken, anonId) => {
  try {
    const response = axios({
      method: "POST",
      url: `${REACT_APP_API_DOMAIN}/api/users/link`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      data: {
        old_user_id: anonId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error ", error);
    return;
  }
};
