/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import * as styles from "./styles";
import {
  PlayIconSmall,
  PauseIconSmall,
  // LinkIcon,
  AudioIcon,
  ThumbsUpIcon,
  ThumbsDownIcon,
} from "./icons";
import theme from "../../theme";
import { EpisodePropType } from "../propTypes";
import { MarkEpisodeFeedback } from "../../apis/episodes";
import { GetFeedsEpisodes } from "../../apis/feeds";

const PlaylistItem = ({
  authToken,
  item,
  episode,
  setEpisode,
  playing,
  setPlaying,
  useAnon,
  setPlaylist,
  selectedFeed,
}) => {
  const handleDateConvert = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const formattedDate = date.toLocaleString(undefined, options);
    return formattedDate;
  };

  const handleLikeFeedback = async (liked) => {
    let newSignal;
    if (liked === item?.episode?.liked) {
      newSignal = null;
    } else {
      newSignal = liked;
    }
    await MarkEpisodeFeedback(
      authToken,
      item?.episode?.uuid,
      newSignal,
      useAnon
    );
    const episodes = await GetFeedsEpisodes(authToken, selectedFeed, useAnon);
    setPlaylist(episodes);
  };

  return (
    <div
      role="button"
      css={[
        styles.PlaylistItem,
        item?.episode?.uuid === episode?.uuid && styles.ItemSelected,
      ]}
      onClick={() => {
        setEpisode(item?.episode);
      }}
    >
      <div css={styles.ItemImageContainer}>
        {item?.episode?.article?.image_link ? (
          // <img
          //   css={styles.ItemImage}
          //   src={item?.episode?.article?.image_link}
          //   alt="episodeImage"
          // />
          <div css={styles.NoImage}>
            <AudioIcon />
          </div>
        ) : (
          <div css={styles.NoImage}>
            <AudioIcon />
          </div>
        )}
      </div>
      <div css={styles.ItemInfo}>
        <div css={styles.TitleContainer}>
          <div
            css={[
              styles.ListenedToIndicator,
              item?.episode?.seen && styles.HideIndicator,
            ]}
          />
          <span css={styles.ItemName}>{item?.episode?.name}</span>
        </div>
        <span css={styles.ItemDescription}>{item?.episode?.description}</span>
        <div css={styles.ItemFooter}>
          <div css={styles.FooterGroup}>
            <span css={styles.ItemTimestamp}>
              {handleDateConvert(item?.episode?.article?.date_written)}
            </span>
            {/* <motion.button
              whileHover={{ scale: theme.animation.whileHover }}
              whileTap={{ scale: theme.animation.whileTap }}
              css={styles.LinkButton}
              onClick={() => {
                window.open(item?.episode?.article?.link, "_blank");
              }}
            >
              <LinkIcon />
            </motion.button> */}
          </div>
          <div css={styles.LikeDislikeGroup}>
            <motion.button
              whileHover={{ scale: theme.animation.whileHover }}
              whileTap={{ scale: theme.animation.whileTap }}
              css={styles.LikeButton}
              onClick={() => {
                handleLikeFeedback(true);
              }}
            >
              <ThumbsUpIcon
                color={
                  item.episode.liked === true
                    ? theme.colors.green800
                    : theme.colors.grey500
                }
              />
            </motion.button>
            <motion.button
              whileHover={{ scale: theme.animation.whileHover }}
              whileTap={{ scale: theme.animation.whileTap }}
              css={styles.LikeButton}
              onClick={() => {
                handleLikeFeedback(false);
              }}
            >
              <ThumbsDownIcon
                color={
                  item.episode.liked === false
                    ? theme.colors.red800
                    : theme.colors.grey500
                }
              />
            </motion.button>
          </div>
        </div>
      </div>

      {playing && item?.episode?.uuid === episode?.uuid ? (
        <motion.button
          whileHover={{ scale: theme.animation.whileHover }}
          whileTap={{ scale: theme.animation.whileTap }}
          css={styles.PlayPauseButton}
          onClick={() => {
            setPlaying(false);
          }}
        >
          <PauseIconSmall />
        </motion.button>
      ) : (
        <motion.button
          whileHover={{ scale: theme.animation.whileHover }}
          whileTap={{ scale: theme.animation.whileTap }}
          css={styles.PlayPauseButton}
          onClick={() => {
            setEpisode(item?.episode);
            setPlaying(true);
          }}
        >
          <PlayIconSmall />
        </motion.button>
      )}
    </div>
  );
};

PlaylistItem.propTypes = {
  authToken: PropTypes.string,
  item: EpisodePropType,
  episode: PropTypes.object,
  setEpisode: PropTypes.func.isRequired,
  playing: PropTypes.bool.isRequired,
  setPlaying: PropTypes.func.isRequired,
  useAnon: PropTypes.bool,
  setPlaylist: PropTypes.func,
  selectedFeed: PropTypes.string,
};

export default PlaylistItem;
