import theme from "../../theme";

export const RefreshIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 19 19"
    fill="none"
  >
    <path
      d="M16.3237 3.99599V7.37099C16.3237 7.52018 16.2645 7.66325 16.159 7.76874C16.0535 7.87423 15.9104 7.93349 15.7612 7.93349H12.3862C12.237 7.93349 12.094 7.87423 11.9885 7.76874C11.883 7.66325 11.8237 7.52018 11.8237 7.37099C11.8237 7.22181 11.883 7.07873 11.9885 6.97325C12.094 6.86776 12.237 6.80849 12.3862 6.80849H14.4035L13.3748 5.77982C12.3294 4.72964 10.91 4.13715 9.42818 4.1324H9.39654C7.92713 4.12895 6.51543 4.70409 5.46678 5.73341C5.35935 5.8337 5.21695 5.88795 5.07004 5.88458C4.92312 5.88121 4.78335 5.82048 4.68064 5.71538C4.57793 5.61028 4.52043 5.46916 4.52043 5.3222C4.52044 5.17525 4.57796 5.03413 4.68068 4.92904C5.9516 3.6869 7.6611 2.99606 9.43819 3.00646C11.2153 3.01686 12.9166 3.72765 14.1729 4.98459L15.1987 6.01326V3.99599C15.1987 3.84681 15.258 3.70373 15.3635 3.59825C15.469 3.49276 15.612 3.43349 15.7612 3.43349C15.9104 3.43349 16.0535 3.49276 16.159 3.59825C16.2645 3.70373 16.3237 3.84681 16.3237 3.99599ZM13.6807 13.5086C12.6217 14.543 11.1975 15.1183 9.71713 15.1096C8.23673 15.1009 6.81945 14.509 5.77264 13.4622L4.74396 12.4335H6.76123C6.91041 12.4335 7.05349 12.3742 7.15898 12.2687C7.26447 12.1633 7.32373 12.0202 7.32373 11.871C7.32373 11.7218 7.26447 11.5787 7.15898 11.4732C7.05349 11.3678 6.91041 11.3085 6.76123 11.3085H3.38623C3.23705 11.3085 3.09397 11.3678 2.98848 11.4732C2.88299 11.5787 2.82373 11.7218 2.82373 11.871V15.246C2.82373 15.3952 2.88299 15.5383 2.98848 15.6437C3.09397 15.7492 3.23705 15.8085 3.38623 15.8085C3.53541 15.8085 3.67849 15.7492 3.78398 15.6437C3.88947 15.5383 3.94873 15.3952 3.94873 15.246V13.2287L4.9774 14.2574C6.23193 15.5182 7.93573 16.2294 9.71436 16.2346H9.75162C11.5151 16.2391 13.2094 15.5487 14.4675 14.3129C14.5702 14.2079 14.6277 14.0667 14.6277 13.9198C14.6277 13.7728 14.5702 13.6317 14.4675 13.5266C14.3648 13.4215 14.225 13.3608 14.0781 13.3574C13.9312 13.354 13.7888 13.4083 13.6814 13.5086H13.6807Z"
      fill={theme.colors.grey100}
    />
  </svg>
);
