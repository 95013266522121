import { useState } from "react";
import Proptypes from "prop-types";
import { MePropType } from "../propTypes";
import WelcomeMessage from "./WelcomeMessage/WelcomeMessage";
import SubscribeMessage from "./SubscribeMessage/SubscribeMessage";

const subscribeMessage = "showSubscribeMessage";
const welcomeMessage = "showWelcomeMessage";

const ShowMessage = ({ feeds, me, authToken }) => {
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(
    localStorage.getItem(welcomeMessage)
  );
  const [showSubscribeMessage, setShowSubscribeMessage] = useState(
    localStorage.getItem(subscribeMessage)
  );

  const determineMessage = () => {
    const hasMaxFeeds = feeds?.length === me?.number_of_feeds;
    if (showWelcomeMessage !== "hide") {
      return (
        <WelcomeMessage
          localStorageKey={welcomeMessage}
          showMessage={showWelcomeMessage}
          setShowMessage={setShowWelcomeMessage}
        />
      );
    } else {
      if (
        showSubscribeMessage !== "hide" &&
        hasMaxFeeds &&
        !me?.subscription_active
      ) {
        return (
          <SubscribeMessage
            localStorageKey={subscribeMessage}
            authToken={authToken}
            showMessage={showSubscribeMessage}
            setShowMessage={setShowSubscribeMessage}
          />
        );
      }
      return;
    }
  };

  return <>{determineMessage()}</>;
};

ShowMessage.propTypes = {
  feeds: Proptypes.array,
  me: MePropType,
  authToken: Proptypes.string,
};

export default ShowMessage;
