import { css } from "@emotion/react";
import theme from "../../../theme";

export const Body = css`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  justify-content: center;
  align-items: center;
`;

export const TriggerButton = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  background: ${theme.colors.primary800};
  color: white;
  &:hover {
    background: ${theme.colors.primary900};
  }
  &:active {
    background: ${theme.colors.primary800};
  }
`;

export const Input = css`
  display: flex;
  padding: 10px;
  border-radius: 10px;
  border: none;
  flex: 1;
  font-size: 14px;
  line-height: 22px;
  width: 350px;
  border: 1px solid ${theme.colors.grey300};
  @media (max-width: 768px) {
    width: 100%;
    box-sizing: border-box;
  }
`;

export const Title = css`
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  color: ${theme.colors.grey800};
`;

export const Description = css`
  font-size: 14px;
  line-height: 22px;
  margin: 0;
  padding: 0;
  color: ${theme.colors.grey500};
  text-align: center;
`;

export const ButtonContainer = css`
  display: flex;
  gap: 10px;
  width: 100%;
`;

export const Button = css`
  display: flex;
  padding: 10px;
  font-size: 14px;
  line-height: 22px;
  border-radius: 10px;
  font-weight: 500;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Cancel = css`
  background: ${theme.colors.grey200};
  color: ${theme.colors.grey500};
  &:hover {
    background: ${theme.colors.grey300};
  }
  &:active {
    background: ${theme.colors.grey200};
  }
`;

export const Submit = css`
  background: ${theme.colors.primary800};
  color: white;
  &:hover {
    background: ${theme.colors.primary900};
  }
  &:active {
    background: ${theme.colors.primary800};
  }
`;
