import theme from "../../theme";

export const PlayIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
  >
    <path
      d="M22.5182 11.0431L9.01071 2.77998C8.78298 2.64053 8.52215 2.56441 8.25516 2.55946C7.98817 2.55452 7.7247 2.62094 7.49196 2.75186C7.26144 2.88075 7.06941 3.06871 6.93562 3.29643C6.80182 3.52414 6.7311 3.78338 6.73071 4.04748V20.5719C6.73245 20.968 6.89143 21.3473 7.17271 21.6263C7.45399 21.9053 7.83454 22.0612 8.23071 22.0597C8.50723 22.0596 8.77838 21.9833 9.01446 21.8394L22.5182 13.5762C22.7353 13.4439 22.9147 13.258 23.0391 13.0364C23.1636 12.8147 23.229 12.5648 23.229 12.3106C23.229 12.0564 23.1636 11.8065 23.0391 11.5848C22.9147 11.3632 22.7353 11.1773 22.5182 11.045V11.0431ZM8.23071 20.554V4.05967L21.7148 12.3097L8.23071 20.554Z"
      fill="white"
    />
    <path
      d="M7.98169 20.8693V3.90527L21.9094 12.3543L7.98169 20.8693Z"
      fill="white"
      stroke="white"
    />
  </svg>
);

export const PauseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
  >
    <path
      d="M18.8806 3.04138H15.1306C14.7328 3.04138 14.3513 3.19942 14.07 3.48072C13.7887 3.76203 13.6306 4.14356 13.6306 4.54138V19.5414C13.6306 19.9392 13.7887 20.3207 14.07 20.602C14.3513 20.8833 14.7328 21.0414 15.1306 21.0414H18.8806C19.2784 21.0414 19.66 20.8833 19.9413 20.602C20.2226 20.3207 20.3806 19.9392 20.3806 19.5414V4.54138C20.3806 4.14356 20.2226 3.76203 19.9413 3.48072C19.66 3.19942 19.2784 3.04138 18.8806 3.04138ZM18.8806 19.5414H15.1306V4.54138H18.8806V19.5414ZM9.13062 3.04138H5.38062C4.98279 3.04138 4.60126 3.19942 4.31995 3.48072C4.03865 3.76203 3.88062 4.14356 3.88062 4.54138V19.5414C3.88062 19.9392 4.03865 20.3207 4.31995 20.602C4.60126 20.8833 4.98279 21.0414 5.38062 21.0414H9.13062C9.52844 21.0414 9.90997 20.8833 10.1913 20.602C10.4726 20.3207 10.6306 19.9392 10.6306 19.5414V4.54138C10.6306 4.14356 10.4726 3.76203 10.1913 3.48072C9.90997 3.19942 9.52844 3.04138 9.13062 3.04138ZM9.13062 19.5414H5.38062V4.54138H9.13062V19.5414Z"
      fill="white"
    />
    <rect
      x="5.25024"
      y="4.37927"
      width="4.12402"
      height="15.1968"
      fill="white"
    />
    <rect
      x="15.0857"
      y="4.44299"
      width="4.12402"
      height="15.1968"
      fill="white"
    />
  </svg>
);

export const SkipForward = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      d="M18.75 3.97144C18.5511 3.97144 18.3603 4.05045 18.2197 4.19111C18.079 4.33176 18 4.52252 18 4.72144V11.2117L6.79031 4.20112C6.56456 4.05941 6.30504 3.98061 6.0386 3.97289C5.77217 3.96517 5.50852 4.02881 5.27494 4.15721C5.04137 4.28562 4.84636 4.47412 4.71011 4.70321C4.57386 4.9323 4.50132 5.19364 4.5 5.46019V20.4827C4.5028 20.7486 4.57626 21.009 4.71285 21.2372C4.84944 21.4655 5.04424 21.6532 5.27731 21.7814C5.51037 21.9095 5.77331 21.9733 6.03917 21.9664C6.30504 21.9594 6.56427 21.8819 6.79031 21.7417L18 14.7311V21.2214C18 21.4203 18.079 21.6111 18.2197 21.7518C18.3603 21.8924 18.5511 21.9714 18.75 21.9714C18.9489 21.9714 19.1397 21.8924 19.2803 21.7518C19.421 21.6111 19.5 21.4203 19.5 21.2214V4.72144C19.5 4.52252 19.421 4.33176 19.2803 4.19111C19.1397 4.05045 18.9489 3.97144 18.75 3.97144ZM6 20.4649V5.47612L17.985 12.9761L6 20.4649Z"
      fill={theme.colors.grey800}
    />
    <path
      d="M6.22485 20.5929V5.00745L17.9753 12.7698L6.22485 20.5929Z"
      fill={theme.colors.grey800}
      stroke={theme.colors.grey800}
    />
  </svg>
);

export const SkipBackward = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
  >
    <path
      d="M18.9966 4.15892C18.7623 4.02911 18.4976 3.96438 18.2298 3.97147C17.9621 3.97856 17.7012 4.05721 17.4741 4.19924L6.2644 11.2117V4.72142C6.2644 4.52251 6.18539 4.33175 6.04473 4.19109C5.90408 4.05044 5.71332 3.97142 5.5144 3.97142C5.31549 3.97142 5.12473 4.05044 4.98407 4.19109C4.84342 4.33175 4.7644 4.52251 4.7644 4.72142V21.2214C4.7644 21.4203 4.84342 21.6111 4.98407 21.7518C5.12473 21.8924 5.31549 21.9714 5.5144 21.9714C5.71332 21.9714 5.90408 21.8924 6.04473 21.7518C6.18539 21.6111 6.2644 21.4203 6.2644 21.2214V14.7311L17.4741 21.7417C17.6998 21.8835 17.9594 21.9622 18.2258 21.97C18.4922 21.9777 18.7559 21.9141 18.9895 21.7856C19.223 21.6572 19.418 21.4687 19.5543 21.2396C19.6905 21.0106 19.7631 20.7492 19.7644 20.4827V5.46017C19.7644 5.19446 19.6931 4.9336 19.5581 4.70476C19.4231 4.47591 19.2292 4.28743 18.9966 4.15892ZM18.2644 20.4714L6.2794 12.9714L18.2644 5.47799V20.4714Z"
      fill={theme.colors.grey800}
    />
    <path
      d="M17.9753 5.00745L17.9753 20.5929L6.22485 12.8305L17.9753 5.00745Z"
      fill={theme.colors.grey800}
      stroke={theme.colors.grey800}
    />
  </svg>
);
