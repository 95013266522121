import axios from "axios";
import { DetermineHeaders } from "./helpers";

const { REACT_APP_API_DOMAIN } = process.env;

export const MarkEpisodeListened = async (authToken, episodeUUID, useAnon) => {
  try {
    const response = await axios({
      method: "PATCH",
      url: `${REACT_APP_API_DOMAIN}/api/episodes/${episodeUUID}`,
      headers: DetermineHeaders(authToken, useAnon),
      data: {
        seen: true,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error ", error);
    return;
  }
};

export const MarkEpisodeFeedback = async (
  authToken,
  episodeUUID,
  liked,
  useAnon
) => {
  try {
    const response = await axios({
      method: "PATCH",
      url: `${REACT_APP_API_DOMAIN}/api/episodes/${episodeUUID}`,
      headers: DetermineHeaders(authToken, useAnon),
      data: {
        liked,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error ", error);
    return;
  }
};
