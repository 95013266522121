import { css } from "@emotion/react";
import theme from "../../theme";

export const Container = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: calc(var(--vh, 1vh) * 100);
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
`;

export const Spacer = css`
  display: flex;
  padding-top: 80px;
`;

export const LoadingContainer = css`
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  color: ${theme.colors.grey500};
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
`;
