import axios from "axios";
import { DetermineHeaders } from "./helpers";

const { REACT_APP_API_DOMAIN } = process.env;

export const GetMe = async (authToken, useAnon) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${REACT_APP_API_DOMAIN}/api/users/me`,
      headers: DetermineHeaders(authToken, useAnon),
    });
    return response.data;
  } catch (error) {
    console.error("Error ", error);
    return;
  }
};
