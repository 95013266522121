/** @jsxImportSource @emotion/react */
import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import * as styles from "./styles";
import Navbar from "../Navbar/Navbar";
import theme from "../../theme";
import Loader from "../Loader";
import { GetMe } from "../../apis/me";
import { v4 as uuidv4 } from "uuid";
import { LinkAnonFeeds } from "../../apis/link";
import LegalContent from "../LegalContent/LegalContent";

const Legal = () => {
  const { isLoading, getIdTokenClaims, isAuthenticated } = useAuth0();
  const [authToken, setAuthToken] = useState(undefined);
  const [me, setMe] = useState(undefined);
  const [useAnon, setUseAnon] = useState(false);

  function setVhProperty() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  window.addEventListener("resize", setVhProperty);
  setVhProperty(); // Set initially on load

  useEffect(() => {
    const fetchIdToken = async () => {
      try {
        if (isAuthenticated && !isLoading) {
          setUseAnon(false);
          const idTokenClaims = await getIdTokenClaims();
          const idToken = idTokenClaims.__raw;
          setAuthToken(idToken);
          let meInfo = await GetMe(idToken);
          const anonToken = localStorage.getItem("anonToken");
          if (meInfo?.has_linked_before === false && anonToken) {
            await LinkAnonFeeds(idToken, anonToken);
            meInfo = await GetMe(idToken);
          }

          setMe(meInfo);
        }
        if (!isAuthenticated && !isLoading) {
          let anonToken = localStorage.getItem("anonToken");
          if (!anonToken) {
            anonToken = uuidv4();
            localStorage.setItem("anonToken", anonToken);
          }
          setUseAnon(true);
          setAuthToken(anonToken);

          const meInfo = await GetMe(anonToken, true);
          setMe(meInfo);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchIdToken();
  }, [isAuthenticated, getIdTokenClaims, isLoading]);

  if (isLoading) {
    return (
      <div css={styles.LoadingContainer}>
        <Loader background={theme.colors.grey500} height={22} width={22} />
        Loading...
      </div>
    );
  }

  return (
    <div css={styles.Container}>
      <Navbar
        authToken={authToken}
        me={me}
        useAnon={useAnon}
        origin={"legal"}
      />
      <LegalContent />
    </div>
  );
};

export default Legal;
