/** @jsxImportSource @emotion/react */
import { useState } from "react";
import * as styles from "./styles";
import Terms from "./Terms";
import Privacy from "./Privacy";

const LegalContent = () => {
  const [selected, setSelected] = useState("terms");

  return (
    <div css={styles.Container}>
      <div css={styles.Tabs}>
        <button
          type="button"
          css={[styles.TabItem, selected === "terms" && styles.Selected]}
          onClick={() => {
            setSelected("terms");
          }}
        >
          Terms
        </button>
        <button
          type="button"
          css={[styles.TabItem, selected === "privacy" && styles.Selected]}
          onClick={() => {
            setSelected("privacy");
          }}
        >
          Privacy
        </button>
      </div>
      {selected === "terms" ? <Terms /> : <Privacy />}
    </div>
  );
};

export default LegalContent;
