import { css } from "@emotion/react";
import theme from "../../theme";

export const Container = css`
  display: flex;
  flex-direction: column;
  width: 80%;
  gap: 20px;
  padding-bottom: 160px;
  box-sizing: border-box;
  overflow-y: auto;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const LoadingContainer = css`
  display: flex;
  gap: 5px;
  box-sizing: border-box;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  box-sizing: border-box;
  padding-top: 100px;
  color: ${theme.colors.grey500};
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
`;

export const Generating = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  color: ${theme.colors.grey500};
  font-size: 14px;
  line-height: 22px;
  gap: 5px;
`;

export const GeneratingRow = css`
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const GeneratingHeadline = css`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;

export const Playlist = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const PlaylistItem = css`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  @media (min-width: 768px) {
    &:hover {
      background: ${theme.colors.grey200};
    }
  }
`;

export const ItemSelected = css`
  background: ${theme.colors.primary200};
`;

export const ItemText = css`
  display: flex;
  white-space: nowrap;
`;

export const ItemImageContainer = css`
  display: flex;
  border: 1px solid ${theme.colors.grey300};
  height: 100%;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 50px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const ItemImage = css`
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const NoImage = css`
  display: flex;
  background: ${theme.colors.grey800};
  width: 100%;
  height: 100%;
  object-fit: cover;
  justify-content: center;
  align-items: center;
`;

export const ItemInfo = css`
  display: flex;
  flex: 1 1 0;
  box-sizing: border-box;
  flex-direction: column;
  gap: 5px;
`;

export const TitleContainer = css`
  display: flex;
  gap: 10px;
  align-items: center;
  flex: 1;
  box-sizing: border-box;
  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;

export const ListenedToContainer = css`
  display: flex;
  height: 100%;
  box-sizing: border-box;
`;

export const ListenedToIndicator = css`
  display: flex;
  box-sizing: border-box;
  border: 1px solid ${theme.colors.red800};
  background: ${theme.colors.red800};
  border-radius: 50%;
  height: 10px;
  width: 10px;
  @media (max-width: 768px) {
    height: 20px;
    width: 10px;
    border-radius: 10px;
  }
`;

export const HideIndicator = css`
  display: none;
`;

export const ItemName = css`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${theme.colors.grey700};
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: ${theme.header.font};
  @media (max-width: 768px) {
    -webkit-line-clamp: 2;
    font-size: 14px;
    line-height: 22px;
  }
`;

export const ItemDescription = css`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${theme.colors.grey600};
  font-size: 14px;
  line-height: 22px;
`;

export const ItemFooter = css`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
`;

export const FooterGroup = css`
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const LikeDislikeGroup = css`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const LikeButton = css`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ItemTimestamp = css`
  color: ${theme.colors.grey500};
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
`;

export const LinkButton = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
  cursor: pointer;
`;

export const PlayPauseButton = css`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  height: 40px;
  width: 40px;
  border-radius: 10px;
  @media (max-width: 768px) {
    display: none;
  }
`;
