import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "@radix-ui/themes/styles.css";
import Home from "./components/Home/Home";
import Legal from "./components/Legal/Legal";
import GlobalStyles from "./GlobalStyles";
import LandingPage from "./components/LandingPage/LandingPage";

const { REACT_APP_AUTH_DOMAIN, REACT_APP_AUTH_CLIENT_ID } = process.env;

const routes = (
  <Auth0Provider
    domain={REACT_APP_AUTH_DOMAIN}
    clientId={REACT_APP_AUTH_CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}
    cacheLocation="localstorage"
  >
    <GlobalStyles />
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/splash" element={<LandingPage />} />
        <Route path="/legal" element={<Legal />} />
      </Routes>
    </Router>
  </Auth0Provider>
);

export default routes;
