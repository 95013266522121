import PropTypes from "prop-types";

export const SourcePropType = PropTypes.shape({
  feed_id: PropTypes.number,
  query: PropTypes.string,
  type: PropTypes.string,
});

export const FeedPropType = PropTypes.shape({
  created_at: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  seed: PropTypes.string,
  source: PropTypes.arrayOf(SourcePropType),
  user_id: PropTypes.string,
  uuid: PropTypes.string,
});

export const ArticlePropType = PropTypes.shape({
  date_written: PropTypes.string,
  image_link: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string,
});

export const EpisodePropType = PropTypes.shape({
  created_at: PropTypes.string,
  episode: PropTypes.shape({
    article: ArticlePropType,
    audio: PropTypes.string,
    description: PropTypes.string,
    first_listened_to: PropTypes.string,
    name: PropTypes.string,
    seen: PropTypes.bool,
    transcript: PropTypes.string,
    uuid: PropTypes.string,
  }),
  episode_id: PropTypes.number,
  feed: PropTypes.shape({
    seed: PropTypes.string,
    uuid: PropTypes.string,
  }),
  feed_id: PropTypes.number,
  id: PropTypes.number,
});

export const MePropType = PropTypes.shape({
  created_at: PropTypes.string,
  email: PropTypes.string,
  id: PropTypes.string,
  metadata: PropTypes.object,
  number_of_feeds: PropTypes.number,
  stripe_customer_id: PropTypes.string,
  stripe_subscription_id: PropTypes.string,
  subscription_active: PropTypes.bool,
  has_linked_before: PropTypes.bool,
});
