/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import * as styles from "./styles";
import PlaylistItem from "./PlaylistItem";
import { EpisodePropType } from "../propTypes";
import Loader from "../Loader";
import theme from "../../theme";

const Playlist = ({
  authToken,
  playlist,
  episode,
  setEpisode,
  playing,
  setPlaying,
  fetchingEpisodes,
  setPlaylist,
  useAnon,
  selectedFeed,
}) => {
  if (fetchingEpisodes) {
    return (
      <div css={styles.LoadingContainer}>
        <Loader background={theme.colors.grey500} height={22} width={22} />
        Loading...
      </div>
    );
  }

  return (
    <div css={styles.Container}>
      <div css={styles.Playlist}>
        {playlist?.map((item) => (
          <PlaylistItem
            key={uuidv4()}
            authToken={authToken}
            item={item}
            episode={episode}
            setEpisode={setEpisode}
            playing={playing}
            setPlaying={setPlaying}
            useAnon={useAnon}
            setPlaylist={setPlaylist}
            selectedFeed={selectedFeed}
          />
        ))}
        {playlist?.length === 0 && (
          <div css={styles.Generating}>
            <div css={styles.GeneratingRow}>
              <Loader
                background={theme.colors.grey500}
                height={22}
                width={22}
              />
              <span css={styles.GeneratingHeadline}>Generating Episodes</span>
            </div>
            <span>May take up to a minute</span>
          </div>
        )}
      </div>
    </div>
  );
};

Playlist.propTypes = {
  authToken: PropTypes.string,
  playlist: PropTypes.arrayOf(EpisodePropType),
  setEpisode: PropTypes.func.isRequired,
  episode: PropTypes.object,
  playing: PropTypes.bool.isRequired,
  setPlaying: PropTypes.func.isRequired,
  fetchingEpisodes: PropTypes.bool.isRequired,
  setPlaylist: PropTypes.func.isRequired,
  useAnon: PropTypes.bool,
  selectedFeed: PropTypes.string,
};

export default Playlist;
