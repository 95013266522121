import { css } from "@emotion/react";
import theme from "../../../theme";

export const Body = css`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  justify-content: center;
  align-items: center;
`;

export const FeedList = css`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  box-sizing: border-box;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid ${theme.colors.grey300};
  border-radius: 10px;
  padding: 5px;
`;

export const FeedItem = css`
  display: flex;
  justify-content: space-between;
  width: 350px;
  align-items: center;
  padding: 5px;
  @media (max-width: 768px) {
    width: 100%;
    box-sizing: border-box;
  }
`;

export const FeedItemDelete = css`
  background: ${theme.colors.red200};
  border-radius: 10px;
`;

export const FeedName = css`
  font-size: 14px;
  line-height: 22px;
  flex: 1;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DeleteConfirmation = css`
  color: ${theme.colors.red800};
  font-weight: 500;
`;

export const TriggerButton = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer;
  background: ${theme.colors.grey700};
  color: white;
  &:hover {
    background: ${theme.colors.grey800};
  }
  &:active {
    background: ${theme.colors.grey700};
  }
`;

export const Input = css`
  display: flex;
  padding: 10px;
  border-radius: 10px;
  border: none;
  flex: 1;
  font-size: 14px;
  line-height: 22px;
  width: 350px;
  border: 1px solid ${theme.colors.grey300};
  @media (max-width: 768px) {
    width: 100%;
    box-sizing: border-box;
  }
`;

export const ConfirmDeleteButtons = css`
  display: flex;
  gap: 5px;
`;

export const ConfirmButton = css`
  display: flex;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 20px;
  border-radius: 10px;
  font-weight: 500;
`;

export const Title = css`
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  padding: 0;
  margin: 0;
  color: ${theme.colors.grey800};
`;

export const Description = css`
  font-size: 14px;
  line-height: 22px;
  margin: 0;
  padding: 0;
  color: ${theme.colors.grey500};
  text-align: center;
`;

export const CancelDelete = css`
  background: ${theme.colors.grey100};
  color: ${theme.colors.grey600};
  &:hover {
    background: ${theme.colors.grey200};
  }
  &:active {
    background: ${theme.colors.grey100};
  }
`;

export const ConfirmDelete = css`
  background: ${theme.colors.red700};
  color: ${theme.colors.red100};
  &:hover {
    background: ${theme.colors.red800};
  }
  &:active {
    background: ${theme.colors.red700};
  }
`;

export const DeleteFeed = css`
  display: flex;
  padding: 5px;
  font-size: 12px;
  line-height: 20px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 500;
  &:hover {
    background: ${theme.colors.red200};
  }
  &:active {
    background: ${theme.colors.red300};
  }
`;

export const ButtonContainer = css`
  display: flex;
  gap: 10px;
  width: 100%;
`;

export const Button = css`
  display: flex;
  padding: 10px;
  font-size: 14px;
  line-height: 22px;
  border-radius: 10px;
  font-weight: 500;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Cancel = css`
  background: ${theme.colors.grey200};
  color: ${theme.colors.grey500};
  &:hover {
    background: ${theme.colors.grey300};
  }
  &:active {
    background: ${theme.colors.grey200};
  }
`;

export const Submit = css`
  background: ${theme.colors.primary800};
  color: white;
  &:hover {
    background: ${theme.colors.primary700};
  }
  &:active {
    background: ${theme.colors.primary800};
  }
`;
