import { css } from "@emotion/react";
import theme from "../../theme";

export const Container = css`
  display: flex;
  flex-direction: column;
  width: 80%;
  gap: 20px;
  padding-top: 80px;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const Tabs = css`
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  background: white;
  padding: 10px 5px;
  border-radius: 10px;
  overflow-x: auto;
  box-sizing: border-box;
  flex-grow: 1;
`;

export const TabItem = css`
  display: flex;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 12px;
  line-height: 20px;
  color: ${theme.colors.grey500};
  font-weight: 500;
  gap: 5px;
`;

export const Selected = css`
  background-color: ${theme.colors.primary200};
  color: ${theme.colors.primary800};
`;

export const Content = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  height: 76vh;
`;

export const Title = css`
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
`;

export const Header = css`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
`;

export const Text = css`
  font-size: 14px;
  line-height: 22px;
`;

export const Link = css`
  color: ${theme.colors.primary800};
`;

export const Section = css`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
