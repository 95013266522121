/** @jsxImportSource @emotion/react */
/* global gtag */
import { useState } from "react";
import PropTypes from "prop-types";
import * as Dialog from "@radix-ui/react-dialog";
import * as modalStyles from "../modalStyles";
import * as styles from "./styles";
import { CreateFeedAndSet } from "../../helpers";

const CreateFeed = ({
  authToken,
  setFeeds,
  open,
  setOpen,
  setSelectedFeed,
  useAnon,
}) => {
  const [feedDescription, setFeedDescription] = useState("");

  const handleCreateFeed = async () => {
    await CreateFeedAndSet(
      authToken,
      feedDescription,
      useAnon,
      setFeeds,
      setSelectedFeed
    );
    gtag("event", "click", {
      event_category: "Feed",
      event_label: "Create New Feed",
    });
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>
        <button
          css={styles.TriggerButton}
          onClick={() => {
            gtag("event", "click", {
              event_category: "Feed",
              event_label: "Open New Feed Modal",
            });
          }}
        >
          New Feed
        </button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay css={modalStyles.Overlay} />
        <Dialog.Content css={modalStyles.Content}>
          <div css={styles.Body}>
            <Dialog.Title css={styles.Title}>Create New Feed</Dialog.Title>
            <Dialog.Description css={styles.Description}>
              What topic do you want to create a feed for?
            </Dialog.Description>
            <input
              css={styles.Input}
              type="text"
              placeholder="Create a feed about..."
              value={feedDescription}
              onChange={(e) => {
                setFeedDescription(e.target.value);
              }}
            />

            <div css={styles.ButtonContainer}>
              <Dialog.Close asChild>
                <button css={[styles.Button, styles.Cancel]}>Cancel</button>
              </Dialog.Close>
              <Dialog.Close asChild>
                <button
                  css={[styles.Button, styles.Submit]}
                  onClick={() => {
                    if (feedDescription?.length > 0) {
                      handleCreateFeed();
                    }
                  }}
                >
                  Create
                </button>
              </Dialog.Close>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

CreateFeed.propTypes = {
  authToken: PropTypes.string,
  setFeeds: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setSelectedFeed: PropTypes.func.isRequired,
  useAnon: PropTypes.bool,
};

export default CreateFeed;
