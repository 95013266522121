/** @jsxImportSource @emotion/react */
import * as styles from "./styles";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { GetFeeds } from "../../apis/feeds";
import CreateFeed from "../Modals/CreateFeed/CreateFeed";
import FeedSettings from "../Modals/FeedSettings/FeedSettings";
import { FeedPropType } from "../propTypes";
import { RefreshIcon } from "./icons";
import { GetFeedsEpisodes } from "../../apis/feeds";
import { MePropType } from "../propTypes";
import { HandleSubscribe } from "../helpers";
import Loader from "../Loader";

const Feeds = ({
  authToken,
  feeds,
  setFeeds,
  selectedFeed,
  setSelectedFeed,
  setFetchingEpisodes,
  setPlaylist,
  me,
  useAnon,
}) => {
  const { loginWithRedirect } = useAuth0();
  const [openCreateFeed, setOpenCreateFeed] = useState(false);
  const [stripeLoading, setStripeLoading] = useState(false);

  useEffect(() => {
    const fetchFeeds = async () => {
      if (authToken && me) {
        const allFeeds = await GetFeeds(authToken, useAnon);
        setFeeds(allFeeds);
        if (allFeeds?.length > 0) {
          setSelectedFeed(allFeeds[0].uuid);
        } else {
          setOpenCreateFeed(true);
        }
      }
    };
    fetchFeeds();
  }, [authToken, setFeeds, setSelectedFeed, useAnon, me]);

  const handleFeedRefresh = async () => {
    setFetchingEpisodes(true);
    const episodes = await GetFeedsEpisodes(authToken, selectedFeed, useAnon);
    setPlaylist(episodes);
    setFetchingEpisodes(false);
  };

  const showUpgradeOrLogin = () => {
    if (
      feeds?.length >= me?.number_of_feeds &&
      !me?.subscription_active &&
      !useAnon
    ) {
      return (
        <button
          css={styles.UpgradeButton}
          onClick={() => {
            HandleSubscribe(authToken, setStripeLoading);
          }}
        >
          {stripeLoading ? (
            <Loader height={22} width={22} background={"white"} />
          ) : (
            "Unlock More"
          )}
        </button>
      );
    }
    if (
      feeds?.length >= me?.number_of_feeds &&
      !me?.subscription_active &&
      useAnon
    ) {
      return (
        <button
          css={styles.UpgradeButton}
          onClick={() => {
            loginWithRedirect();
            window.gtag_start_login_signup();
            window.fbq("track", "Lead", {});
          }}
        >
          Join to Unlock
        </button>
      );
    }
    return;
  };

  const handleFeedSelect = (feed) => {
    setSelectedFeed(feed.uuid);
  };

  return (
    <div css={styles.Container}>
      <div css={styles.SectionHeader}>
        <span css={styles.SectionTitle}>Your Feeds</span>
        <div css={styles.SectionHeaderButtons}>
          {feeds?.length < me?.number_of_feeds && (
            <CreateFeed
              open={openCreateFeed}
              setOpen={setOpenCreateFeed}
              authToken={authToken}
              setFeeds={setFeeds}
              setSelectedFeed={setSelectedFeed}
              useAnon={useAnon}
            />
          )}
          {showUpgradeOrLogin()}

          <button
            css={styles.SectionButton}
            onClick={() => {
              handleFeedRefresh();
            }}
          >
            <RefreshIcon />
          </button>
          <FeedSettings
            authToken={authToken}
            feeds={feeds}
            setFeeds={setFeeds}
            setSelectedFeed={setSelectedFeed}
            useAnon={useAnon}
          />
        </div>
      </div>

      <div css={styles.Filters}>
        {feeds?.map((feed) => (
          <button
            css={[
              styles.FeedItem,
              feed.uuid === selectedFeed && styles.Selected,
            ]}
            key={uuidv4()}
            onClick={() => {
              handleFeedSelect(feed);
            }}
          >
            <span css={styles.FeedName}>{feed.name}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

Feeds.propTypes = {
  authToken: PropTypes.string,
  feeds: PropTypes.arrayOf(FeedPropType),
  setFeeds: PropTypes.func.isRequired,
  selectedFeed: PropTypes.string,
  setSelectedFeed: PropTypes.func.isRequired,
  setFetchingEpisodes: PropTypes.func.isRequired,
  setPlaylist: PropTypes.func.isRequired,
  me: MePropType,
  useAnon: PropTypes.bool,
};

export default Feeds;
