import { css, keyframes } from "@emotion/react";

export const overlayShow = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const contentShow = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`;

export const smallContentShow = keyframes`
  from {
    opacity: 0;
    transform: scale(0.96);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}`;

export const Overlay = css`
  background-color: #00000070;
  position: fixed;
  inset: 0;

  animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
`;

export const Content = css`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  animation: ${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 768px) {
    animation: ${smallContentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    top: unset;
    left: 0;
    bottom: 0;
    padding-bottom: env(safe-area-inset-bottom);
    transform: unset;
    width: 100%;
    box-sizing: border-box;
  }
`;
