import { css } from "@emotion/react";
import theme from "../../theme";

export const Container = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: calc(var(--vh, 1vh) * 100);
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  justify-content: center;
`;

export const Card = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const Headline = css`
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
`;

export const Body = css`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
`;

export const Button = css`
  display: flex;
  padding: 10px;
  border-radius: 10px;
  background: ${theme.colors.primary800};
  color: ${theme.colors.grey100};
  align-self: flex-start;
  cursor: pointer;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
`;

export const LoadingContainer = css`
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  color: ${theme.colors.grey500};
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
`;
