import { css } from "@emotion/react";
import theme from "../../theme";

export const Container = css`
  display: flex;
  flex-direction: column;
  width: 80%;
  gap: 5px;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const SectionHeader = css`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: flex-end;
`;

export const SectionTitle = css`
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  color: ${theme.colors.grey500};
`;

export const SectionHeaderButtons = css`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const UpgradeButton = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  background: ${theme.colors.primary800};
  color: white;
  &:hover {
    background: ${theme.colors.primary900};
  }
  &:active {
    background: ${theme.colors.primary800};
  }
`;

export const SectionButton = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 5px 10px;
  background: ${theme.colors.grey700};
  cursor: pointer;
  &:hover {
    background: ${theme.colors.grey800};
  }
  &:active {
    background: ${theme.colors.grey700};
  }
`;

export const AddButton = css`
  background: ${theme.colors.primary800};
  color: white;
  &:hover {
    background: ${theme.colors.primary700};
  }
  &:active {
    background: ${theme.colors.primary800};
  }
`;

export const SettingsButton = css`
  background: ${theme.colors.grey700};
  &:hover {
    background: ${theme.colors.grey600};
  }
  &:active {
    background: ${theme.colors.grey700};
  }
`;

export const Filters = css`
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  background: white;
  padding: 10px 5px;
  border-radius: 10px;
  overflow-x: auto;
`;

export const FeedItem = css`
  display: flex;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 12px;
  line-height: 20px;
  color: ${theme.colors.grey500};
  font-weight: 500;
  gap: 5px;
`;

export const FeedName = css`
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Selected = css`
  background-color: ${theme.colors.primary200};
  color: ${theme.colors.primary800};
`;
