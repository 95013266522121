/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import * as styles from "./styles";
import {
  LogOut,
  ManageSubscriptionIcon,
  SupportIcon,
  LegalIcon,
} from "./icons";
import { HandleSubscribe } from "../helpers";
import Loader from "../Loader";
import { GetStripeSubscription } from "../../apis/subscription";
import { MePropType } from "../propTypes";

const AccountDropdown = ({
  authToken,
  me,
  useAnon,
  showAccountDropdown,
  dropdownRef,
}) => {
  const [stripeLoading, setStripeLoading] = useState(false);
  const { logout, user } = useAuth0();

  const handleGetSubscription = async () => {
    setStripeLoading(true);
    const response = await GetStripeSubscription(authToken);
    setStripeLoading(false);
    window.location.href = response.url;
  };

  const handleSupport = async () => {
    window.location.href =
      "mailto:support@wire.fm?subject=Support+Request:+Assistance+Needed";
  };

  const handleLegal = async () => {
    window.location.href = "/legal";
  };

  return (
    <div
      css={[
        styles.AccountDropdown,
        showAccountDropdown && styles.ShowAccountDropdown,
      ]}
      ref={dropdownRef}
    >
      <span css={styles.Username}>{user?.name}</span>
      {!me?.subscription_active && !useAnon && (
        <button
          css={styles.BuyCredits}
          onClick={() => {
            HandleSubscribe(authToken, setStripeLoading);
          }}
        >
          {stripeLoading ? (
            <Loader background="#ffffff" height={22} width={22} />
          ) : (
            "Subscribe"
          )}
        </button>
      )}
      <div css={styles.DropdownGroup}>
        {me?.subscription_active && (
          <button
            type="button"
            css={styles.AccountOption}
            onClick={handleGetSubscription}
          >
            {stripeLoading ? (
              <Loader background="#ffffff" height={22} width={22} />
            ) : (
              <>
                <ManageSubscriptionIcon />
                <span>Subscription</span>
              </>
            )}
          </button>
        )}
        <button
          type="button"
          css={styles.AccountOption}
          onClick={handleSupport}
        >
          <SupportIcon />
          Help
        </button>
        <button type="button" css={styles.AccountOption} onClick={handleLegal}>
          <LegalIcon />
          Terms & Privacy
        </button>
        <button
          type="button"
          css={styles.AccountOption}
          onClick={() =>
            logout({ logoutParams: { returnTo: window.location.origin } })
          }
        >
          <LogOut />
          <span>Log Out</span>
        </button>
      </div>
    </div>
  );
};

AccountDropdown.propTypes = {
  authToken: PropTypes.string,
  me: MePropType,
  useAnon: PropTypes.bool,
  showAccountDropdown: PropTypes.bool,
  dropdownRef: PropTypes.object,
};

export default AccountDropdown;
