const theme = {
  default: {
    font: "Poppins, sans-serif",
    size: "14px",
    lineHeight: "20px",
  },
  header: {
    font: "Libre Baskerville, sans-serif",
  },
  colors: {
    grey800: "#131A24",
    grey700: "#303945",
    grey600: "#667180",
    grey500: "#A6ABB2",
    grey300: "#E6E7E8",
    grey200: "#F5F5F7",
    grey100: "#FAFBFC",
    red800: "#FF6767",
    red700: "#FF8080",
    red600: "#FF9999",
    red300: "#FFE0E0",
    red200: "#FFF0F0",
    red100: "#FFFAFA",
    primary900: "#1A5DFF",
    primary800: "#3772FF",
    primary700: "#427AFF",
    primary600: "#5285FF",
    primary500: "#80A5FF",
    primary300: "#E0EAFF",
    primary200: "#F0F4FF",
    primary100: "#FAFBFF",
    yellow800: "#FFCF56",
    yellow700: "#FFDB80",
    yellow600: "#FFE39E",
    yellow300: "#FFF6E0",
    yellow200: "#FFFBF0",
    yellow100: "#FFFEFA",
    green800: "#7DDE92",
    green700: "#8BE09E",
    green600: "#97E5A8",
    green300: "#E0FFE7",
    green200: "#F0FFF3",
    green100: "#FAFFFB",
  },
  button: {
    fontSize: "14px",
    lineHeight: "22px",
    padding: "10px 12px",
    borderRadius: "10px",
    fontWeight: "500",
  },
  animation: {
    whileHover: 1.05,
    whileTap: 0.95,
  },
};

export default theme;
