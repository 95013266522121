import React from "react";
import { createRoot } from "react-dom/client";
import routes from "./routes";

if (
  window.location.protocol === "http:" &&
  window.location.host !== "localhost:3000"
) {
  window.location.replace(
    `https://${window.location.host}${window.location.pathname}`
  );
}

if (window.location.host === "www.wire.fm") {
  window.location.replace("https://wire.fm");
}

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<>{routes}</>);
