/** @jsxImportSource @emotion/react */
import * as styles from "./styles";

const Terms = () => {
  return (
    <div css={styles.Content}>
      <span css={styles.Title}>Terms of Service</span>
      <span css={styles.Text}>
        Welcome to Wire.fm, a audio news feed creation service operated by
        Loopgenius, Inc. (hereafter referred to as &quot;Wire.fm&quot;,
        &quot;we&quot;, &quot;us&quot;, or &quot;our&quot;). By accessing or
        using our services, you agree to be bound by the following terms and
        conditions (the &quot;Terms of Service&quot;). If you do not agree to
        these terms, please do not use our services.
      </span>
      <div css={styles.Section}>
        <span css={styles.Header}>Service Description</span>
        <span css={styles.Text}>
          Wire.fm provides a variety of services, including generation of news
          articles in audio form. Our services are designed provide you with the
          latest insights on a given topic or trend. We strive to provide our
          users with the best possible service, but we do not guarantee that our
          services will always be available or error-free.
        </span>
      </div>

      <div css={styles.Section}>
        <span css={styles.Header}>User Responsibilities</span>
        <span css={styles.Text}>
          As a user of Wire.fm, you are responsible for the following: Providing
          accurate and complete information when creating your account. Keeping
          your account information and passwords confidential. Using our
          services only for lawful purposes. Not using our services to engage in
          any illegal or fraudulent activity. Not using our services to transmit
          any material that is harmful, offensive, or otherwise violative of any
          laws or regulations. Complying with all applicable laws and
          regulations.
        </span>
      </div>

      <div css={styles.Section}>
        <span css={styles.Header}>Marketing Communication Consent</span>
        <span css={styles.Text}>
          By using our services, you agree to opt into receiving marketing
          emails from Wire.fm and its affiliated companies and brands. If you
          wish to opt-out from receiving such emails, you can do so at any time
          by clicking the unsubscribe link in the emails.
        </span>
      </div>

      <div css={styles.Section}>
        <span css={styles.Header}>Proprietary Rights</span>
        <span css={styles.Text}>
          All content, trademarks, and data on our website and provided through
          our services are the property of LoopGenius, Inc. or its licensors and
          are protected by United States and international copyright and
          trademark laws. You may not use any content, trademark, or data
          without our express written consent.
        </span>
      </div>

      <div css={styles.Section}>
        {" "}
        <span css={styles.Header}>Termination of Service</span>
        <span css={styles.Text}>
          We reserve the right to terminate or suspend your access to our
          services at any time, with or without notice, for any reason,
          including for violation of these Terms of Service.
        </span>
      </div>

      <div css={styles.Section}>
        <span css={styles.Header}>Disclaimer of Warranties</span>
        <span css={styles.Text}>
          OUR SERVICES ARE PROVIDED &quot;AS IS&quot; AND &quot;AS
          AVAILABLE.&quot; WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND,
          WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT. WE MAKE NO WARRANTY THAT (I) OUR SERVICES WILL MEET
          YOUR REQUIREMENTS, (II) OUR SERVICES WILL BE UNINTERRUPTED, TIMELY,
          SECURE, OR ERROR-FREE, (III) THE RESULTS THAT MAY BE OBTAINED FROM THE
          USE OF OUR SERVICES WILL BE ACCURATE OR RELIABLE, (IV) THE QUALITY OF
          ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR
          OBTAINED BY YOU THROUGH OUR SERVICES WILL MEET YOUR EXPECTATIONS, AND
          (V) ANY ERRORS IN THE SOFTWARE WILL BE CORRECTED.
        </span>
      </div>

      <div css={styles.Section}>
        <span css={styles.Header}>Limitation of Liability</span>
        <span css={styles.Text}>
          IN NO EVENT SHALL WE BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
          SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED
          TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER
          INTANGIBLE LOSSES (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
          SUCH DAMAGES), RESULTING FROM: (I) THE USE OR THE INABILITY TO USE OUR
          SERVICES; (II) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND
          SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES
          PURCHASED OR OBTAINED THROUGH OR FROM OUR SERVICES; (III) UNAUTHORIZED
          ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (IV) STATEMENTS
          OR CONDUCT OF ANY THIRD PARTY ON OUR SERVICES; (V) ANY OTHER MATTER
          RELATING TO OUR SERVICES. IN ANY CASE, OUR LIABILITY TO YOU SHALL BE
          LIMITED TO THE AMOUNT YOU PAID TO US FOR THE USE OF OUR SERVICES.
        </span>
      </div>

      <div css={styles.Section}>
        <span css={styles.Header}>Indemnification</span>
        <span css={styles.Text}>
          You agree to indemnify, defend, and hold harmless Wire.fm, its
          officers, directors, employees, agents, licensors, and suppliers from
          and against any claims, actions or demands, liabilities, and
          settlements, including without limitation, reasonable legal and
          accounting fees, resulting from, or alleged to result from, your
          violation of these Terms of Service.
        </span>
      </div>

      <div css={styles.Section}>
        <span css={styles.Header}>Governing Law</span>
        <span css={styles.Text}>
          These Terms of Service shall be governed by and construed in
          accordance with the laws of the United States and the State of
          California, without giving effect to any principles of conflicts of
          law.
        </span>
      </div>

      <div css={styles.Section}>
        <span css={styles.Header}>Changes to the Terms of Service</span>
        <span css={styles.Text}>
          We reserve the right to make changes to these Terms of Service at any
          time. If we make changes, we will provide notice of such changes, such
          as by sending an email notification, providing notice through our
          website, or updating the &quot;Last Updated&quot; date below. Your
          continued use of our services after the changes are made shall be
          deemed to be your acceptance of the new terms.
        </span>
      </div>

      <div css={styles.Section}>
        {" "}
        <span css={styles.Header}>30-day money-back guarantee</span>
        <span css={styles.Text}>
          Our 30-day money-back guarantee is designed to give you peace of mind
          when subscribing to Wire.fm. This guarantee applies exclusively to the
          subscription service fee for the first 30 days of your membership,
          currently priced at USD$5 (note that this price is subject to change).
          To obtain a refund of the subscription fee, you can reach out to{" "}
          <a href="mailto:support@wire.fm" css={styles.Link}>
            support@wire.fm
          </a>{" "}
          to request a refund. Any refund provided will also result in the
          immediate cancellation of your account. It is limited solely to the
          subscription fee of first-time customers who did not receive a free
          trial, offering a risk-free opportunity to experience the value
          Wire.fm brings.
        </span>
      </div>

      <div css={styles.Section}>
        <span css={styles.Header}>Contact Information</span>
        <span css={styles.Text}>
          If you have any questions about these Terms of Service, please contact
          us at:{" "}
          <a css={styles.Link} href="mailto:legal@wire.fm">
            legal@wire.fm
          </a>
        </span>
        <span css={styles.Text}>
          These Terms of Service were last updated on <b>09/05/2024</b>.
        </span>
      </div>
    </div>
  );
};

export default Terms;
